import {ref, onMounted, computed} from 'vue'
import {get, sync, commit, call} from 'vuex-pathify'
import {useStore} from 'vuex'
import dayjs from 'dayjs'
import useExciseTaxRates from '@composables/useExciseTaxRates'

export default function useExciseExceptionsValidation(store) {
  const {getTaxRate} = useExciseTaxRates(store)
  const selectedProject = computed(() => store.get('project/selectedProject'))
  const selectedProjectStartTime = computed(() =>
    dayjs(selectedProject.value.AuditStart)
      .toDate()
      .getTime()
  )
  const selectedProjectEndTime = computed(() =>
    dayjs(selectedProject.value.AuditEnd)
      .toDate()
      .getTime()
  )

  const stratum = computed(() =>
    store.get('project/selectedProjectExciseStratum')
  )
  const luCounty = computed(() =>
    store.get('luCounty/luCounty').filter((x) => x.DORJurisdiction != null)
  )
  // const luCity = computed(() => store.get('luCity/luCity'))
  // const luSpecialDistrict = computed(() =>
  //   store
  //     .get('luSpecialDistrict/luSpecialDistrict')
  //     .filter((x) => x.IsActive && x.DORJurisdiction != null)
  // )
  const luExciseIssues = computed(() =>
    store.get('luExciseIssues/luExciseIssues').filter((x) => x.IsActive)
  )
  const luExciseTransactionType = computed(() =>
    store
      .get('luExciseTransactionType/luExciseTransactionType')
      .filter((x) => x.IsActive)
  )

  const luExciseTaxTypes = computed(() =>
    store.get('luExciseTaxTypes/luExciseTaxTypes')
  )
  const availableTaxTypes = computed(() => {
    var projectDetailsID = selectedProject.value.ProjectDetailsID
    return luExciseTaxTypes.value.filter((item) => {
      return item.IsActive && item.ProjectDetailID == projectDetailsID
    })
  })
  const validateString = (value, canBeEmpty) => {
    if (
      typeof value == 'string' ||
      typeof value == 'number' ||
      typeof value == 'boolean'
    ) {
      return canBeEmpty || value.toString().length > 0
    } else {
      return false
    }
  }
  const validateStringMaxLength = (value, maxCharacters) => {
    if (
      typeof value == 'string' ||
      typeof value == 'number' ||
      typeof value == 'boolean'
    ) {
      return value.toString().length < maxCharacters
    } else {
      return false
    }
  }
  const validateValueIsInList = (value, items, required) => {
    let matchingIndex = -1
    let list = items.value ? items.value : items
    for (var i = 0; i < list.length; i++) {
      if (list[i].ID == value) {
        matchingIndex = i
        break
      }
    }
    if (required) {
      return matchingIndex > -1
    } else {
      return (
        value == 0 || value == null || value == undefined || matchingIndex > -1
      )
    }
  }
  const validateException = (exception) => {
    let valid = true
    var validationMessage = []
    //validate stratum
    //map stratumId from stratumNumber if stratumId is not defined
    if (!(exception.StratumID > 0) && exception.StratumNumber != null) {
      let matchingIndex = -1
      // let stratum = items.value ? items.value : items
      for (var i = 0; i < stratum.value.length; i++) {
        if (stratum.value[i].StratumNumber == exception.StratumNumber) {
          matchingIndex = i
          break
        }
      }
      if (matchingIndex != -1) {
        exception.StratumID = stratum.value[matchingIndex].ID
      }
    }
    if (!validateValueIsInList(exception.StratumID, stratum, true)) {
      valid = false
      let stratumMessage = !exception.StratumID
        ? 'no stratum id'
        : 'stratum id is not in project'
      validationMessage.push(stratumMessage)
    }
    //validate date
    let dateTime = dayjs(exception.InvoiceDate)
      .toDate()
      .getTime()
    var low = dateTime < selectedProjectStartTime.value
    var high = dateTime > selectedProjectEndTime.value
    if (high || low || dateTime != dateTime) {
      valid = false
      var dateMessage = high
        ? 'date is after audit end'
        : low
        ? 'date is before audit start'
        : 'date is not valid'
      validationMessage.push(dateMessage)
    }
    //validate issue
    if (!validateValueIsInList(exception.IssueID, luExciseIssues, true)) {
      valid = false
      var issueMessage = !exception.IssueID
        ? 'no issue selected'
        : 'invalid issue selected'
      validationMessage.push(issueMessage)
    }
    // validate transaction type
    if (
      !validateValueIsInList(
        exception.TransactionTypeID,
        luExciseTransactionType,
        true
      )
    ) {
      valid = false
      var transactionMessage = !exception.TransactionTypeID
        ? 'no transaction type selected'
        : 'invalid transaction type selected'
      validationMessage.push(transactionMessage)
    }
    // validate tax type
    if (!validateValueIsInList(exception.TaxTypeID, availableTaxTypes, true)) {
      valid = false
      var taxMessage = !exception.TaxTypeID
        ? 'no tax type selected'
        : 'invalid tax type selected'
      validationMessage.push(taxMessage)
    }
    // validate sold from county
    // if (!validateValueIsInList(exception.SoldFromCountyID, luCounty, true)) {
    //   valid = false
    //   var sfCountyMessage = !exception.SoldFromCountyID
    //     ? 'no sold from county selected'
    //     : 'invalid sold from county selected'
    //   validationMessage.push(sfCountyMessage)
    // }
    // validate delivered to County
    if (!validateValueIsInList(exception.DeliveredToCountyID, luCounty, true)) {
      valid = false
      var dtCountyMessage = !exception.DeliveredToCountyID
        ? 'no delivered to county selected'
        : 'invalid delivered to county selected'
      validationMessage.push(dtCountyMessage)
    }
    // validate city
    let cityList = []
    let matchingCountyIndex = -1
    let countyList = luCounty.value
    for (var i = 0; i < countyList.length; i++) {
      if (countyList[i].ID == exception.DeliveredToCountyID) {
        matchingCountyIndex = i
        break
      }
    }
    if (matchingCountyIndex > -1) {
      cityList = countyList[matchingCountyIndex].Cities.filter(
        (x) => x.IsActive && x.DORJurisdiction != null
      )
    }
    if (!validateValueIsInList(exception.DeliveredToCityID, cityList)) {
      valid = false
      var dtCityMessage = 'invalid delivered to city selected'
      validationMessage.push(dtCityMessage)
    }
    // Validate special district
    let sdList = []
    if (matchingCountyIndex > -1) {
      sdList = countyList[matchingCountyIndex].SpecialDistricts.filter(
        (x) => x.IsActive && x.DORJurisdiction != null
      )
    }
    if (!validateValueIsInList(exception.SpecialDistrictID, sdList)) {
      valid = false
      var dtSDMessage = 'invalid special district selected'
      validationMessage.push(dtSDMessage)
    }
    // validate taxable amount
    if (
      parseFloat(exception.TaxableAmount) != parseFloat(exception.TaxableAmount)
    ) {
      valid = false
      var taxableAmountMessage = 'invalid taxable amount'
      validationMessage.push(taxableAmountMessage)
    }
    var reconciliationIssues = [8, 10]
    if (exception.TaxableAmount == 0) {
      //parse AuditedTax and ReportedTax for reconciliation issues to taxable amount and tax paid
      if (
        parseFloat(exception.AuditedTax) == parseFloat(exception.AuditedTax) &&
        reconciliationIssues.includes(exception.IssueID)
      ) {
        if (
          !exception.IssueID ||
          !exception.InvoiceDate ||
          !exception.DeliveredToCountyID ||
          !exception.TaxTypeID
        ) {
          valid = false
          var taxableAmountMessage = 'unable to determine taxable amount'
          validationMessage.push(taxableAmountMessage)
        }
        var taxRate = parseFloat(
          getTaxRate(
            exception.IssueID,
            exception.InvoiceDate,
            exception.DeliveredToCountyID,
            exception.TaxTypeID,
            exception.DeliveredToCityID,
            exception.SpecialDistrictID
          ).toFixed(4)
        )
        exception.TaxableAmount = parseFloat(
          (exception.AuditedTax / taxRate).toFixed(2)
        )
      }
    }
    // validate tax paid
    if (parseFloat(exception.TaxPaid) != parseFloat(exception.TaxPaid)) {
      valid = false
      var taxPaidMessage = 'invalid tax paid'
      validationMessage.push(taxPaidMessage)
    }
    if (
      exception.TaxPaid == 0 &&
      reconciliationIssues.includes(exception.IssueID) &&
      parseFloat(exception.ReportedTax) == parseFloat(exception.ReportedTax)
    ) {
      exception.TaxPaid = exception.ReportedTax
    }
    // validate isActive
    if (!exception.IsActive === true && !exception.IsActive === false) {
      valid = false
      var isActiveMessage = 'invalid is active'
      validationMessage.push(isActiveMessage)
    }
    // validate description
    if (!validateString(exception.Description, true)) {
      valid = false
      var descriptionMessage = 'invalid description'
      validationMessage.push(descriptionMessage)
    }
    // validate reference
    if (!validateString(exception.Reference)) {
      valid = false
      var referenceMessage = 'invalid reference'
      validationMessage.push(referenceMessage)
    }
    // validate sub description
    // if (!validateString(exception.SubDescription, true)) {
    //   valid = false
    //   var subDescriptionMessage = 'invalid sub description'
    //   validationMessage.push(subDescriptionMessage)
    // }
    // validate vendorCustomer
    if (!validateString(exception.VendorCustomer, true)) {
      valid = false
      var vendorCustomerMessage = 'invalid vendor / customer'
      validationMessage.push(vendorCustomerMessage)
    }
    // validate document number
    if (!validateString(exception.DocumentNumber, true)) {
      valid = false
      var documentNumberMessage = 'invalid document number'
      validationMessage.push(documentNumberMessage)
    } else if (!validateStringMaxLength(exception.DocumentNumber, 50)) {
      valid = false
      var documentNumberMessage =
        'document number must be 50 characters or less'
      validationMessage.push(documentNumberMessage)
    }
    if (valid) {
      return valid
    } else {
      // console.orange(exception, validationMessage)
      return validationMessage
    }
  }

  return {
    validateException,
  }
}
