<template>
  <BasePanelWithHeader headerText="Exceptions">
    <template v-slot:content>
      <v-dialog
        persistent
        v-model="dialog"
        @keydown.esc="dialog = false"
        width="850"
        scrollable
      >
        <v-form ref="form" @submit.prevent>
          <v-card class="elevation-3">
            <v-card-title class="primary text-h5 white--text">
              Import
            </v-card-title>
            <v-card-text class="pa-4">
              <v-progress-linear
                :active="loadingDoc"
                :indeterminate="loadingDoc"
              ></v-progress-linear>
              <div>
                Importing from an excel file will clear the grid of any existing
                rows, Make sure to save any unsaved items before uploading
              </div>
              <br />
              <div
                >If you do not provide a Stratum ID, the system will attempt to
                find it for you based on the Stratum Number column. If a Stratum
                ID is specified in the row any Stratum Number in that row will
                not be used.</div
              >
              <br />
              <div
                >If you are entering reconciliations, you may enter the Audited
                Tax and Reported Tax instead of the Taxable Amount and Tax
                Paid.</div
              >
              <br />
              <div>
                The following headers can be used in any order, and the header
                text can contain spaces, a header row must be used if using a
                stratum number instead of the stratum id, or if using the
                reconciliation fields.
              </div>
              <br />
              <div>{{ importHeaderList.join(', ') }}</div>
              <div class="dropbox">
                <input class="input-file" type="file" @change="getFiles" />
                <p v-if="uploadList.length == 0" class="extra-margin">
                  Drag file to upload or click to browse
                </p>
                <div v-if="uploadList.length > 0" class="extra-margin">
                  <p
                    class="uploadList"
                    v-for="(item, index) in items"
                    :key="index"
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
              <v-row>
                <v-col sm="12"> </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="uploadDocument"
                :disabled="loadingDoc"
                color="primary"
                >Upload</v-btn
              >
              <v-btn outlined color="primary" @click="dialog = false"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog
        persistent
        v-model="savedItemsDialog"
        scrollable
        max-width="1250"
      >
        <v-card class="elevation-3">
          <v-card-title class="white--text text-h5 primary"
            >Exceptions saved</v-card-title
          >
          <v-card-text class="pa-4">
            <v-list>
              <v-list-item>
                {{ returnedItems.length }} items saved.</v-list-item
              >
              <v-list-item
                v-if="returnedItems.length > gridLimit"
                class="warning"
              >
                if shown in grid, only the first {{ gridLimit }} items will be
                displayed</v-list-item
              >
            </v-list>
          </v-card-text>
          <v-card-actions>
            <excelExport
              :data="returnedItems"
              :exportFields="getExcelFields(fullHeaderList)"
              worksheet="Excise Exceptions"
              :name="`${fileName}.xlsx`"
              >Download Saved Items</excelExport
            >
            <v-btn color="primary" @click="showReturnedItemsInGrid"
              >Show in grid</v-btn
            >
            <v-btn color="primary" @click="closeSavedItemsDialog">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        persistent
        v-model="importValidationMessageDialog"
        max-width="950"
        scrollable
      >
        <v-card class="elevation-3">
          <v-card-title class="white--text text-h5 primary"
            >Exception Summary</v-card-title
          >
          <v-card-text class="pa-4">
            <div v-if="invalidImported.length > 0">
              <div>
                {{ validImported.length }} items out of
                {{ validImported.length + invalidImported.length }} have been
                validated.
              </div>
              <div>
                There are {{ invalidImported.length }} invalid rows in the
                imported file.
              </div>

              <div v-if="invalidImported.length > gridLimit" class="warning">
                Please review the invalid items and retry the upload.
              </div>
              <v-virtual-scroll
                :items="invalidImported"
                height="300"
                item-height="40"
              >
                <template v-slot:default="{item}">
                  <v-list>
                    <v-list-item :key="item.row">
                      row {{ item.row }}:
                      <span
                        class="pl-1"
                        v-for="(entry, index) in item.invalidEntries"
                        >{{ entry
                        }}{{
                          index == item.invalidEntries.length - 1 ? '' : ', '
                        }}
                      </span>
                    </v-list-item>
                  </v-list>
                </template>
              </v-virtual-scroll>
            </div>
            <div v-else>
              {{ validImported.length }} items have been validated.
            </div>
          </v-card-text>
          <v-card-actions>
            <excelExport
              v-if="invalidImported.length > 0"
              :data="validationExport"
              :exportFields="getExcelFields(['row', 'validationMessages'])"
              worksheet="Excise Exceptions Validation"
              :name="`Exception validation summary.xlsx`"
              >Download Invalid Item Summary</excelExport
            >
            <v-btn
              v-if="
                invalidImported.length < gridLimit && invalidImported.length > 0
              "
              color="primary"
              @click="showInvalidInGrid"
              >Review Invalid in grid</v-btn
            >
            <v-btn
              v-if="invalidImported.length == 0"
              color="primary"
              @click="saveValidItems"
              >Import Items</v-btn
            >
            <v-btn color="primary" @click="closeImportValidationDialog"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        @keydown.esc="relationsDialog = false"
        v-model="relationsDialog"
        scrollable
        max-width="1250"
      >
        <v-card class="elevation-3">
          <v-card-title class="white--text text-h5 primary"
            >Item Options</v-card-title
          >
          <v-card-text class="pa-4">
            <v-tabs v-model="relationTab">
              <v-tab v-for="(item, key, index) in relations" :key="index">
                {{
                  key.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1')
                }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="relationTab">
              <v-tab-item v-for="(items, key, index) in relations" :key="index">
                <BaseSimpleDataTable
                  :items="items"
                  search
                ></BaseSimpleDataTable>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn
              @click="downloadImportFields"
              outlined
              color="primary"
              class="ml-3"
              >Download Import Document</v-btn
            > -->
            <v-btn color="primary" @click="relationsDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row
        ><v-col class="mr-auto">
          <v-btn @click="dialog = true" class="primary"
            ><v-icon class="pr-2">mdi-microsoft-excel</v-icon>Import</v-btn
          >
          <v-btn
            @click="downloadImportFields"
            outlined
            color="primary"
            class="ml-3"
            >Download Import Template</v-btn
          >
          <v-btn
            @click="relationsDialog = true"
            outlined
            color="primary"
            class="ml-3"
            >View Available Options</v-btn
          >
          <BaseHelpTooltip
            v-if="invalidImported.length > 0"
            icon="mdi-alert-circle-check-outline"
            iconColor="primary"
            tooltipText="Review import validation messages"
            header="Validation messages"
            :maxWidth="850"
          >
            <v-row
              ><v-col>
                <div v-if="invalidImported.length > 0">
                  There were {{ invalidImported.length }} invalid rows in the
                  imported file.
                  <v-virtual-scroll
                    :items="invalidImported"
                    height="300"
                    item-height="40"
                  >
                    <template v-slot:default="{item}">
                      <v-list>
                        <v-list-item :key="item.row">
                          row {{ item.row }}:
                          <span
                            class="pl-1"
                            v-for="(entry, index) in item.invalidEntries"
                            >{{ entry }}
                            <span
                              v-if="!index == item.invalidEntries.length - 1"
                              >,
                            </span></span
                          >
                        </v-list-item>
                      </v-list>
                    </template>
                  </v-virtual-scroll>
                </div>
              </v-col></v-row
            >
          </BaseHelpTooltip>
        </v-col>
      </v-row>
      <ExciseExceptionsToastGrid
        :mode="mode"
        ref="exceptionGrid"
        :items="exciseExceptions"
        :additionalItems="validImported"
        @itemAdded="itemAdded"
        @itemModified="itemModified"
        @bulkSave="bulkSaveReturn"
        @clearGrid="clearGrid"
      ></ExciseExceptionsToastGrid>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {inject} from 'vue'
import {get, sync, commit, call} from 'vuex-pathify'
import {mapMutations} from 'vuex'
import ExciseExceptionsToastGrid from '@components/grid/ExciseExceptionsToastGrid'
import ExciseExceptions from '@classes/ExciseExceptions'
import {authMethods, authComputed} from '@state/helpers'
import XLSX from 'xlsx'
import useExciseExceptionsValidation from '@composables/useExciseExceptionsValidation'
import excelExport from '@components/excelExport'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {
    ExciseExceptionsToastGrid,
    excelExport,
  },
  setup(props, context) {
    const store = inject('vuex-store')
    const {validateException} = useExciseExceptionsValidation(store)
    return {
      validateException,
    }
  },
  filters: {},
  data() {
    return {
      dialog: false,
      exciseExceptions: [],
      uploadList: [],
      loadingDoc: false,
      items: [],
      importFromExcel: false,
      validImported: [],
      invalidImported: [],
      returnedItems: [],
      gridLimit: 250,
      savedItemsDialog: false,
      importValidationMessageDialog: false,
      relationsDialog: false,
      relationTab: '',
      importHeaderList: [
        'IsActive',
        'StratumID',
        'StratumNumber',
        'TransactionTypeID',
        'TaxTypeID',
        'InvoiceDate',
        'VendorCustomer',
        'DocumentNumber',
        'IssueID',
        'Description',
        'TaxableAmount',
        'TaxPaid',
        'DeliveredToCountyID',
        'DeliveredToCityID',
        'SpecialDistrictID',
        'Reference',
        'ID',
        'ReportedTax',
        'AuditedTax',
      ],
      fullHeaderList: [
        'IsActive',
        'StratumID',
        'TransactionTypeID',
        'TaxTypeID',
        'InvoiceDate',
        'VendorCustomer',
        'DocumentNumber',
        'IssueID',
        'Description',
        'TaxableAmount',
        'TaxPaid',
        'TaxRate',
        'TaxDue',
        'DeliveredToCountyID',
        'DeliveredToCityID',
        'SpecialDistrictID',
        'Reference',
        'CreatedByID',
        'ModifiedByID',
        'ID',
        'ReportedTax',
        'AuditedTax',
        // 'SoldFromCountyID',
        // 'SubDescription',
      ],
    }
  },
  created() {},
  beforeDestroy() {
    console.red('add before destroy')
  },
  computed: {
    ...authComputed,
    ...get('project', ['selectedProject']),
    ...get('exciseExceptions', ['relations']),
    mode() {
      return this.importFromExcel ? 'import' : 'add'
    },
    fileName() {
      return `${
        this.selectedProject.ProjectNumber
      } Excise Exceptions - saved ${new Date()
        .toLocaleString()
        .replace(/[\/\:]/gi, '-')}`
    },
    validationExport() {
      return this.invalidImported.map((item) => {
        return {
          row: item.row,
          validationMessages: item.invalidEntries.join(', '),
        }
      })
    },
    // to_json() {
    //   var sheet = this.worksheet
    //   var roa = XLSX.utils.sheet_to_json(sheet, {
    //     range: 1,
    //   })
    //   if (roa.length) {
    //   }
    //   console.green(roa)
    // },
  },
  methods: {
    getExcelFields(stringArray) {
      let fields = {}
      stringArray.forEach((item) => {
        fields[item] = item
      })
      return fields
    },
    ...call('exciseExceptions', ['downloadImportFields']),
    handleError: call('errors/handleError'),
    toastMessage: call('messages/toastMessage'),
    updateExceptionItemInList(data) {
      //update array
      const {index, item} = data
      if (index >= 0) {
        let match = this.exciseExceptions[index]
        if (!match) {
          //item was appended to grid, and is not in the source array
          // console.devlog('no match', index, item, match)
          this.exciseExceptions.splice(index, 1, item)
        } else if (match.ID == item.ID || match.ID == 0) {
          // console.devlog('match', index, item, match)
          this.exciseExceptions.splice(index, 1, item)
        } else {
          //match, but item has ID mismatch
          console.devlog('match, but item has ID mismatch')
        }
        // console.devlog(this.exciseExceptions)
      } else if (index == -1) {
        this.exciseExceptions.unshift(item)
      }
    },
    getFiles: function (event) {
      this.uploadList = []
      this.items = []
      for (let file in event.target.files) {
        this.uploadList.push(event.target.files[file])
      }
      for (let i = 0; i < this.uploadList.length - 2; i++) {
        if (this.uploadList[i].name) this.items.push(this.uploadList[i])
      }
    },
    closeSavedItemsDialog() {
      this.savedItemsDialog = false
    },
    showReturnedItemsInGrid() {
      this.exciseExceptions = this.returnedItems.slice(0, this.gridLimit)
      this.savedItemsDialog = false
    },
    closeImportValidationDialog() {
      this.importValidationMessageDialog = false
      this.importFromExcel = false
      this.validImported = []
      this.invalidImported = []
    },
    saveValidItems() {
      this.$refs.exceptionGrid.saveAdditionalOnly()
      this.importValidationMessageDialog = false
    },
    showInvalidInGrid() {
      this.exciseExceptions = this.invalidImported.map((item) => {
        return {
          ...item.item,
          row: item.row,
        }
      })
      this.importValidationMessageDialog = false
    },
    uploadDocument() {
      this.loadingDoc = true
      this.importFromExcel = true
      this.validImported = []
      this.invalidImported = []
      console.devlog('importStart')
      this.$nextTick(() => {
        for (let i = 0; i < this.uploadList.length - 2; i++) {
          let file = this.uploadList[i]
          let fileReader = new FileReader()
          if (fileReader && file) {
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = (e) => {
              // let contents = fileReader.result.split(',')[1]
              var data = new Uint8Array(e.target.result)
              var wb = XLSX.read(data, {type: 'array', cellDates: true})
              if (wb && wb.SheetNames.length > 0) {
                //get first sheet
                var sheet = wb.Sheets[wb.SheetNames[0]]
                let headers = []
                let range = XLSX.utils.decode_range(sheet['!ref'])
                let fullHeaderList = this.fullHeaderList.filter((x) => true)
                let importOptions = {}
                //this checks for header row on row 1 and 2
                let C = range.s.c,
                  R = range.s.r /* start in the first row */
                /* walk every column in the range */
                for (R = range.s.r; R < 2; R++) {
                  headers[R] = []
                  for (C = range.s.c; C <= range.e.c; ++C) {
                    let cell =
                      sheet[
                        XLSX.utils.encode_cell({c: C, r: R})
                      ] /* find the cell in the first row */

                    let hdr = ''
                    if (cell && cell.t)
                      hdr = XLSX.utils.format_cell(cell).replace(/\s/gi, '')

                    headers[R].push(hdr)
                  }
                }
                //match the header based on either StratumID or StratumNumber
                let headerMatch = headers.findIndex(
                  (x) => x.includes('StratumID') || x.includes('StratumNumber')
                )
                // set range if sheet has header row
                if (headerMatch >= 0) {
                  for (var col = range.s.c; col <= range.e.c; ++col) {
                    /* find the cell object */
                    // strip spaces fron headers
                    var cellref = XLSX.utils.encode_cell({
                      c: col,
                      r: headerMatch,
                    }) // construct A1 reference for cell
                    if (!sheet[cellref]) continue // if cell doesn't exist, move on
                    var cell = sheet[cellref]

                    /* if the cell is a text cell with the old string, change it */
                    if (!(cell.t == 's' || cell.t == 'str')) continue // skip if cell is not text
                    var newValue = XLSX.utils
                      .format_cell(cell)
                      .replace(/\s/gi, '')
                    cell.w = newValue // change the cell value - w is where the header text is pulled from
                    // cell.v = newValue // change the cell value
                    // cell.h = newValue // change the cell value
                  }
                  importOptions.range = headerMatch
                } else if (headers[0].length == 20) {
                  var remove = ['ReportedTax', 'AuditedTax']
                  importOptions.header = fullHeaderList.filter(
                    (i) => !remove.includes(i)
                  )
                } else if (headers[0].length == 17) {
                  var remove = [
                    'ID',
                    'CreatedByID',
                    'ModifiedByID',
                    'ReportedTax',
                    'AuditedTax',
                  ]
                  importOptions.header = fullHeaderList.filter(
                    (i) => !remove.includes(i)
                  )
                } else if (headers[0].length == 16) {
                  var remove = [
                    'ID',
                    'CreatedByID',
                    'ModifiedByID',
                    'IsActive',
                    'ReportedTax',
                    'AuditedTax',
                  ]
                  importOptions.header = fullHeaderList.filter(
                    (i) => !remove.includes(i)
                  )
                } else if (headers[0].length == 15) {
                  var remove = [
                    'ID',
                    'CreatedByID',
                    'ModifiedByID',
                    'TaxRate',
                    'TaxDue',
                    'ReportedTax',
                    'AuditedTax',
                  ]
                  importOptions.header = fullHeaderList.filter(
                    (i) => !remove.includes(i)
                  )
                } else if (headers[0].length == 14) {
                  var remove = [
                    'ID',
                    'CreatedByID',
                    'ModifiedByID',
                    'TaxRate',
                    'TaxDue',
                    'IsActive',
                    'ReportedTax',
                    'AuditedTax',
                  ]
                  importOptions.header = fullHeaderList.filter(
                    (i) => !remove.includes(i)
                  )
                } else {
                  //error - unable to determine headers
                  this.handleError([
                    'unable to determine headers for file, please check the file and try again.',
                    'The following headers can be used',
                    `${this.importHeaderList.join(', ')}`,
                  ])
                  this.importFromExcel = false
                  this.loadingDoc = false
                  return
                }
                var roa = XLSX.utils.sheet_to_json(sheet, importOptions)
                if (roa.length) {
                  var validArray = []
                  var invalidArray = []
                  for (let i = 0; i < roa.length; i++) {
                    let exception = new ExciseExceptions(roa[i]).root()
                    if (exception.ID == 0) {
                      exception.CreatedByID = this.currentUser.ID
                    }
                    // exception.ID = 0
                    let validation = this.validateException(exception)
                    if (validation !== true) {
                      var invalidItem = {
                        row: i + 1 + (headerMatch + 1) || 0,
                        item: exception,
                        invalidEntries: validation,
                      }
                      invalidArray.push(invalidItem)
                    } else {
                      validArray.push(exception)
                    }
                  }
                  this.validImported = validArray
                  this.invalidImported = invalidArray
                  this.importValidationMessageDialog = true
                  this.loadingDoc = false
                } else {
                  this.loadingDoc = false
                  this.importFromExcel = false
                  this.handleError([
                    `no items found in file, please check the file and try again`,
                  ])
                }
              }
              this.dialog = false
            }
          }
        }
      })
    },
    appendRow(number) {
      let num = parseInt(number)
      if (num == parseInt(number)) {
        // this.$refs.exceptionGrid.appendRows(num)
        // console.devlog('appendRows', num)
        var currentData = this.$refs.exceptionGrid.getData()
        var list = []

        for (var i = 0; i < num; i++) {
          list.push(
            new ExciseExceptions({CreatedByID: this.currentUser.ID}).root()
          )
        }
        for (var i = 0; i < currentData.length; i++) {
          list.push(new ExciseExceptions(currentData[i]).root())
        }
        this.exciseExceptions = list
        // console.devlog('append rows done')
      }
    },
    itemModified(data) {
      this.updateExceptionItemInList(data)
    },
    itemAdded(data) {
      // console.devlog(data)
      this.updateExceptionItemInList(data)
    },
    bulkSaveReturn(data) {
      // var emptyItemString = JSON.stringify(
      //   new ExciseExceptions({
      //     CreatedByID: this.currentUser.ID,
      //   }).removeRelated()
      // )
      // var list = this.exciseExceptions.filter((item) => {
      //   return JSON.stringify(item) != emptyItemString
      // })
      // data.forEach((item) => {
      //   let match = list.findIndex((x) => x.ID == item.ID)
      //   if (match > -1) {
      //     list.splice(match, 1, item)
      //   } else {
      //     list.push(item)
      //   }
      // })
      // give user option to show in grid (limited count) or download file

      this.returnedItems = data
      this.savedItemsDialog = true

      this.importFromExcel = false
      this.validImported = []
      this.invalidImported = []
      this.exciseExceptions = []
    },
    clearGrid() {
      this.importFromExcel = false
      this.validImported = []
      this.invalidImported = []
      this.exciseExceptions = []
    },
  },
  watch: {},
}
</script>
<style scoped>
.dropbox {
  outline: 2px dashed rgb(190, 190, 190); /* the dash box */
  outline-offset: -10px;
  background: #fafafa;
  color: var(--v-accent-base);
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  height: 100px;
  margin-top: 10px;
}

.input-file {
  outline: 2px solid black;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
</style>
